import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql, Script } from "gatsby";

function Seo({
  description,
  lang,
  meta,
  keywords,
  title,
  paypalScript = false,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={title /*`%s`*/}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            {" "}
            {/* <link
              href="https://fonts.googleapis.com/?display=swap"
              rel="preconnect"
            /> */}
            {paypalScript && (
              /* <Script src="https://www.paypal.com/sdk/js?client-id=AVEDco2ZH2Ers4xO2U3_pRM8EuyAJax2flsW1r7vdL6TbpImgGLEUilN0MXfaead9bVNvMfBjzbok7d7" /> 
              <Script src="https://www.paypal.com/sdk/js?client-id=ATkQ4Kf7D96akda5iHlhVc8qYl0QEGKwxYqLgl-HVA1P3fCpgHWnrfSREirQIXp1GRTPuRuQG0_PQXVL" />*/
              <Script src="https://www.paypal.com/sdk/js?&currency=ILS&client-id=AfEK_nWIJ-Ne4fF9GU0QP4q2J95-mSibqCx5O86vQnWERRDjTuchNrLVOALPhJF-LOi3tgHZMMekLKrD"></Script>
            )}
          </Helmet>
          /*   <Helmet>
            ‍<title>{title}</title>‍
            <meta name="description" content={metaDescription} />
            <meta name="title" content={title} />
          </Helmet> */
        );
      }}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
